@tailwind base;
@tailwind components;
@tailwind utilities;



* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  .z-back{
    z-index: -1;
  }
  
  .about-heading{
    font-size: 50px;
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  body {
    background-color: #1b1b1b;
    color: white;
  }
  
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 8vh;
    background-color: #1b1b1b;
    color: white;
    padding: 0 10%;
  }
  
  .banner {
    cursor: pointer;
    font-size: 50px;
    font-family: 'Dancing Script', cursive;
    margin-top: 1%;
  }
  
.nav ul {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav ul li {
  cursor: pointer;
  margin-left: 20px;
  transition: color 0.3s ease;
  white-space: nowrap;
}
  
  .nav ul li:hover {
    color: #b81c1c;
  }
  
  .resume {
    font-size: 14px;
    padding: 8px 16px;
    border: none;
    background-color: #e85a19;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .resume:hover {
    background-color: #b81c1c;
  }

.start{
    display: flex;
    min-height: 80vh;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    top: 15%;
    
}

.intro{
    margin-left: 5%;
    float: right;
    text-align: center;
    background-color: #ff9500;
    margin-right: 5%;
}

.Hello{
    margin-right: 15%;
    align-items: center;
    font-weight: 600;
}

.social-icons {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 5%;
    margin-top: 2%;

  }
  
  .social-icons a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #1b1b1b;
    transition: all 0.3s ease;
  }
  
  .social-icons a:hover {
    transform: scale(1.1);
    background-color: #1b1b1b;
    color: #ffffff;
  }
  
  .social-icons a:last-child {
    margin-right: 0;
  }
  

.name{
    color: #ff0062;
}

.line{
    font-size: 25px;
}

.buttons {
    margin: 10%;
    text-align: center;
}

.btn-hover {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    margin-top: 25px;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-2 {
    background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
    box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}

.skills{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1b1b1b;
    color: white;
    margin-top: 2%;
    margin-bottom: 3%;
}

.skill-heading{
    font-size: 50px;
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    margin-top: 5%;
    margin-bottom: 5%;
}

.skill-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    justify-items: center;
    text-align: center;
    margin: 10px;
  }
  
  .skill {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 10px;
    width: 10%;
    border-radius: 10px;
    transition: all 0.3s ease;
  }

    .skill:hover {
        transform: scale(1.2);   
    }

  .skill-icon{
    font-size: 50px;
    margin-bottom: 10px;
  }

  .project-heading{
    font-size: 50px;
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    margin-top: 5%;
    margin-bottom: 5%;
}

  .project-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    justify-items: center;
    text-align: center;
    margin: 10px;
    padding: 50px;
  }

  .project {
    /* position: relative; */
  }
  
  .project img {
    width: 400px;
    height: 300px;
    object-fit: cover;
    transition: transform 0.3s ease;
    border: 4px solid #f5ce62;
  }
  
  .project:hover img {
    transform: scale(1);
    filter: brightness(1.2);
  }
  
  .project::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  
  .project:hover::after {
    opacity: 1;
  }
  
  .project-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .project-block-title {
    font-size: 2.7rem;
    font-weight: 600;
    width: 400px;
    margin-bottom: 10px;
  }
  
  .project-block-desc {
    font-size: 1.5rem;
    font-weight: 400;
    width: fit-content;
    margin-bottom: 10px;
  }

  .button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
  }

  .button-code{
    width: 130px;
    padding: 15px;
    border-radius: 40px;
    border: none;
    cursor: pointer;
  }

  .button-code:hover{
    background-color: #f5ce62;
  }

  .contact-heading{
    font-size: 50px;
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .contact-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1b1b1b;
    color: white;
    padding: 5% 10%;
  }
  
  .contact-heading {
    font-size: 50px;
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .left-side {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .social-icon-contact {
    display: flex;
  }
  
  .social-icon {
    font-size: 30px;
    margin-right: 10px;
    color: #ffffff;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #b81c1c;
  }
  
  .nav-icon{
    visibility: hidden;
  }

  .right-side {
    display: flex;
    justify-content: center;
    margin-top: 15%;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-input {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
  }
  
  .contact-submit {
    width: 120px;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #e85a19;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-submit:hover {
    background-color: #b81c1c;
  }
  
  .content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
    transition: all 0.3s ease;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(255, 166, 0, 0.8),
        hsla(17, 100%, 50%, 0.8)   
    )!important;
}


@media (max-width: 767px) {
  .start{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px;
    margin-top: 30%;
    text-align: center;
  }

  .Hello{
    margin-right: 0;
    text-align: center;
  }

  .banner {
    cursor: pointer;
    position: absolute;
    width: 200px;
    font-size: 32px;
    margin-left: 0;
    padding-left: 0px;
    font-family: 'Dancing Script', cursive;
    margin-left: 10px;
  }

  .name{
    font-size: 2rem;
    text-align: center;
    display: inline;
  }

  .contact-dir{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contact-icons{
    margin-bottom: 25px;
  }

  .social-icons{
    justify-content: center;
    margin-right: 0;
    margin-top: 5%;
  }

  .intro{
    width: 200px;
  }

  .nav-control{
    visibility: hidden;
  }

  .nav{
    padding: 0;
    margin: 0;
  }
}